<template>
  	<b-card>
		<b-row class="mb-1">
		<b-col>
			<Filters
			:perPage="perPage"
			:perPageOptions="perPageOptions"
			:searchQuery="searchQuery"
			@change-per-page="changePerPage"
			@search-in-table-records="changeSearchQuery"
			/>
		</b-col>
		<b-col md="3">
			<b-button
			class="float-right custom-button-margin mr-0"
			variant="primary"
			@click="createRecord()"
			><feather-icon icon="PlusIcon" size="16" />{{ $t('Add')}} </b-button>
		</b-col>
		</b-row>

		<b-table
			:items="accessRegisters"
			:fields="fields"
			responsive
			small
			:filter="searchQuery"
			:sort-desc.sync="isSortDirDesc"
			show-empty
			:filter-included-fields="filterOn"
			:busy.sync="isLoadingMyPropertyData"
			:thead-class="(accessRegisters.length) ? '' : 'd-none'"
			class="position-relative mb-0 minHTable"
			sticky-header="40rem"
			no-border-collapse
		>
			<template #table-busy>
				<div class="text-center my-2">
				<b-spinner class="align-middle" label="Loading..." variant="success"></b-spinner>
				<strong> {{$t('loading')}}... </strong>
				</div>
			</template>

			<template #empty>
				<b-alert show variant="warning" class="p-1 text-center mt-2">{{$t('There are no records to show')}}</b-alert>
			</template>
			<template #cell()="data">
				<div class="p-custom">{{ data.value }}</div>
			</template>
			<template #cell(guestData)="row">
				<div class="text-center p-0 m-0">
					<b-badge :id="'register-guests-'+row.item.id" variant="primary">
						<span class="p-custom"> {{row.item.guestData.length}} </span>
					</b-badge>
					<b-popover :target="'register-guests-'+row.item.id" triggers="hover" placement="auto">
						<template #title>{{ $t('guests') }}</template>
						<!-- <h5>{{row.item.reasonName}}</h5>
						<hr class="custom-button-margin"> -->
						<li v-for="(guest, index) in row.item.guestData" :key="index">
							{{guest.name}} {{guest.lastname}} <br>
							<label class="ml-1">{{guestType(guest.type)}} <span v-if="guest.email">- {{guest.email}}</span></label>
						</li>
					</b-popover>
				</div>
			</template>

			<template #cell(actions)="row">
		
				<b-dropdown toggle-class="p-0" no-caret right>
					<template #button-content>
						<b-button variant="primary" size="sm">
						<feather-icon icon="MoreVerticalIcon" size="16" class="align-middle" style="color: white" />
						</b-button>
					</template>
					<b-dropdown-item v-if="!row.item.active" @click="seeRecord(row.item)">
						<feather-icon icon="EyeIcon" />
						<span class="align-middle ml-50"> {{$t('See')}}</span>
					</b-dropdown-item>
					<b-dropdown-item v-if="row.item.active" @click="editRecord(row.item)">
						<feather-icon icon="Edit2Icon" />
						<span class="align-middle ml-50"> {{$t('Edit')}}</span>
					</b-dropdown-item>
					<b-dropdown-item v-if="row.item.active" @click="deleteRecord(row.item)">
						<feather-icon icon="TrashIcon" />
						<span class="align-middle ml-50"> {{$t('Cancel')}}</span>
					</b-dropdown-item>
				</b-dropdown>
			</template>

		</b-table>
		

		<PaginationTable
			:currentPage="currentPage"
			:totalRows="accessControlList.length"
			:perPage="perPage"
			@change-page="changePage"
			v-show="accessControlList.length > accessRegisters.length"

		/>
  </b-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { formatDate, formatTime, showAlertMessage } from '@/helpers/helpers'
import Filters from '@/modules/myProperty/components/Filters'
import PaginationTable from '@/modules/myProperty/components/PaginationTable'
import { utils } from "@/modules/owners/mixins/utils"

export default {
    mixins:[ utils ],
	components: {
		Filters,
		PaginationTable
	},
  props: {
    isLoadingMyPropertyData: {
      type: Boolean,
      default: false,
      required: false
    },
  },
	data(){
		return {
			perPage: 10,
			currentPage: 1,
			perPageOptions: [5, 10, 15, 20, 25, 50, 100],
			searchQuery: '',
			sortBy: 'id',
			isSortDirDesc: false,
			filterOn: ["guestData", "ownerName"],
			fields:[
				{ key:'reasonName', label:this.$t('Reason')},
				{ key:'dateIn', sortable: true, label: this.$t('AccessDate'), formatter: value => {
					return formatDate({date: value, language: this.user?.languageName})
				}},
				{ key:'timeIn', sortable: true, label:this.$t('timeIn'), class:'text-center', formatter: value => {
					return formatTime({time: value, format:"HH:mm:ss", language: this.user?.languageName})
				}},
				{ key:'dateOut', sortable: true, label: this.$t('ExitDate'), formatter: value => {
					return formatDate({date: value, language: this.user?.languageName})
				}},
				{ key:'timeOut', sortable: true, label:this.$t('timeOut'), class:'text-center', formatter: value => {
					return formatTime({time: value, format:"HH:mm:ss", language: this.user?.languageName})
				}},
				{ key:'guestData', label:this.$t('guests')},
				{ key:'ownerName', label:this.$t('access grantor') },
				// { key:'active', label:this.$t('active') },
				{ key:'actions', label:this.$t('Actions')}
			],
			typeGuests: [
				{value: "1", text: this.$t('Adult')},
				{value: "2", text: this.$t('Children')}
			],
		}
	},
	computed: {
		...mapState('auth', ['user', 'userAdmin']),
		...mapState('myProperty', ['accessControlList']),
		accessRegisters(){
			const items = this.accessControlList
			return items.slice( (this.currentPage - 1) * this.perPage, this.currentPage * this.perPage )
		},
	},
	methods:{
		...mapActions('myProperty', ['deleteControlAccess']),
		guestType(type){
			return this.typeGuests?.find(guest=> guest.value == type)?.text || ''
		},
		changePage(page){
			this.currentPage = page
		},
		changePerPage(perPage){
			this.perPage = perPage
		},
		changeSearchQuery(searchQuery){
			this.searchQuery = searchQuery
		},
		async seeRecord(item){
			this.$router.push({name: 'access-control', params: { id: item.id } })
		},
		async editRecord(item){
			this.$router.push({name: 'edit-access-control', params: { id: item.id } })
			// push a back del registro				
			await this.pushData('onClick', 'goToMyPropertyEditControlRegister', 'goToMyPropertyEditControlRegister', this.$route.name) 

		},
		async createRecord(){
			this.$router.push({name: 'create-access-control'})
			// push a back del registro			
			await this.pushData('onClick', 'goToMyPropertyCreateControlRegister', 'goToMyPropertyCreateControlRegister', this.$route.name) 

		},
		async deleteRecord(data){
      	// validar si es admin desde mixin en utils
			if( this.isAdmin ){
				return this.$swal.fire({icon: "error", title: `${this.$t("Restricted Access")}`, html: `${this.$t("Text Restricted Access Admin")}`});
			}
      
			const response = await this.deleteControlAccess({idAccess: data.id})
			if (response) {
				showAlertMessage( this.$t('Access deleted'), 'InfoIcon', `🎉🎉 ${this.$t('Register deleted')}`, 'success', 4000, 'bottom-right')
				this.$emit('request-access-control')
				await this.pushData('onClick', 'accessControlLogWasDeleted', 'accessControlLogWasDeleted', this.$route.name) 
			}
		}
	},	
}
</script>

<style>
.custom-button-margin{
  margin: 0.3rem !important;
}
.p-custom{
  padding: 0.3rem;
}
.minHTable{
  min-height: 300px;
}
</style>