<template>
  <b-card>
    <b-row class="mb-1">
      <b-col>
        <Filters
          :perPage="perPage"
          :perPageOptions="perPageOptions"
          :searchQuery="searchQuery"
          @change-per-page="changePerPage"
          @search-in-table-records="changeSearchQuery"
        />
      </b-col>
      <b-col md="3">
        <b-button
          class="float-right custom-button-margin mr-0"
          variant="primary"
          @click="createRecord()"
        ><feather-icon icon="PlusIcon" size="16" />{{ $t('Add')}} </b-button>
      </b-col>
    </b-row>
    <b-table
      :items="accessRegisters"
      :fields="fields"
      responsive
      :filter="searchQuery"
      :sort-desc.sync="isSortDirDesc"
      show-empty
      :filter-included-fields="filterOn"
      :busy.sync="isLoadingTable"
      :thead-class="(accessRegisters.length) ? '' : 'd-none'"
      class="position-relative mb-0"
			sticky-header="40rem"
      no-border-collapse
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle" label="Loading..." variant="success"></b-spinner>
          <strong> {{$t('loading')}}... </strong>
        </div>
      </template>
      <template #empty>
        <b-alert show variant="warning" class="p-1 text-center mt-2">{{$t('There are no records to show')}}</b-alert>
      </template>

      <template #cell(statusname)="row">
        <b-badge pill :class="badgeStatus(row.item).variant">{{badgeStatus(row.item).statusName}}  </b-badge>
      </template>
      <template #cell(actions)="row">
        <b-dropdown toggle-class="p-0" no-caret right>
          <template #button-content>
            <b-button variant="primary" size="sm">
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle" style="color: white" />
            </b-button>
          </template>
          <b-dropdown-item @click="editRequest(row.item)">
            <feather-icon :icon="editButton(row.item).icon" />
            <span class="align-middle ml-50"> {{editButton(row.item).name}}</span>
          </b-dropdown-item>
          <b-dropdown-item v-if="row.item.status != 3" @click="cancelRequest(row.item)">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50"> {{$t('Cancel')}}</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <PaginationTable
      :currentPage="currentPage"
      :totalRows="maintenanceRequestsList.length"
      :perPage="perPage"
      @change-page="changePage"
      v-show="maintenanceRequestsList.length > accessRegisters.length"
    />
  </b-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { formatDate, showAlertMessage } from '@/helpers/helpers'
import Filters from '@/modules/myProperty/components/Filters'
import PaginationTable from '@/modules/myProperty/components/PaginationTable'
import { utils } from "@/modules/owners/mixins/utils"

export default {
  mixins:[ utils ],
  components: {
    Filters,
    PaginationTable
  },
  props: {
    isLoadingMyPropertyData: {
      type: Boolean,
      default: false,
      required: false
    },
  },
  data(){
    return {
      isUpdatingMyPropertyData: false,
      perPage: 10,
			currentPage: 1,
			perPageOptions: [5, 10, 15, 20, 25, 50, 100],
			searchQuery: '',
			sortBy: 'id',
			isSortDirDesc: false,
			filterOn: ["reason", "providerName"],
			fields:[
				{ key:'reason', label:this.$t('Reason'), class: 'font-weight-bold'},
				{ key:'startDate', sortable: true, label: this.$t('Initial date'), formatter: value => {
					return formatDate({date: value, language: this.user?.languageName})
				}},
				{ key:'endDate', sortable: true, label: this.$t('End date'), formatter: value => {
					return formatDate({date: value, language: this.user?.languageName})
				}},
				{ key:'providerName', label:this.$t('Provider')},
				{ key:'statusname', label:this.$t('Status') },
				{ key:'actions', label:this.$t('Actions')}
			],
		}
	},
	computed: {
		...mapState('auth', ['user', 'userAdmin']),
		...mapState('myProperty', ['maintenanceRequestsList']),
		accessRegisters(){
			const items = this.maintenanceRequestsList
			return items.slice( (this.currentPage - 1) * this.perPage, this.currentPage * this.perPage )
		},
    isLoadingTable(){
      return this.isLoadingMyPropertyData || this.isUpdatingMyPropertyData
    }
	},
	methods:{
		...mapActions('myProperty', ['cancelMaintenanceRequest']),
		badgeStatus(request){
			const { status } = request

			let variant =  'bg-success'
			let statusName = this.$t("Confirmed")

			if(status === '1' ){
				variant = 'bg-info'
				statusName = this.$t("Pending") //  1-> Pendiente
			}

			if(status === '2' ){
				variant = 'bg-success'
				statusName = this.$t("Confirmed") // 2-> es confirmada
			}

			if( status === '3' ){
				variant = 'bg-danger'
				statusName = this.$t("Cancelled")
			}

			if( status === '4' ){
				variant = 'bg-warning'
				statusName = this.$t("Rejected")
			}

			return {variant, statusName}
		},
		changePage(page){
			this.currentPage = page
		},
		changePerPage(perPage){
			this.perPage = perPage
		},
		changeSearchQuery(searchQuery){
			this.searchQuery = searchQuery
		},
    editButton(item){
      return ['2', '3'].includes(item?.status) ? {name: this.$t('See'), icon: 'EyeIcon'} : {name: this.$t('Edit'), icon: 'EditIcon'}
    },
		async seeRecord(item){
			this.$router.push({name: 'access-control', params: { id: item.id } })
		},
		async editRequest(item){
      		if (['2', '3'].includes(item?.status)){
				this.$router.push({name: 'maintenance-request', params: { id: item.id } })
				await this.pushData('onClick', 'goToShowMaintenanceDataRequest', 'goToShowMaintenanceDataRequest', this.$route.name)

			} else {
				this.$router.push({name: 'edit-maintenance-request', params: { id: item.id } })
				await this.pushData('onClick', 'goToEditMaintenanceDataRequest', 'goToEditMaintenanceDataRequest', this.$route.name)
			}
		},
		async createRecord(){
			this.$router.push({name: 'create-maintenance-request'})
			// push a back del registro
			await this.pushData('onClick', 'goToMyPropertyCreateMaintenanceRequest', 'goToMyPropertyCreateMaintenanceRequest', this.$route.name)
		},
		async cancelRequest(data){
      		// validar si es admin desde mixin en utils
			if( this.isAdmin ){
				return this.$swal.fire({icon: "error", title: `${this.$t("Restricted Access")}`, html: `${this.$t("Text Restricted Access Admin")}`});
			}

      		this.isUpdatingMyPropertyData = true

			const response = await this.cancelMaintenanceRequest({id: data.id, idOwner:this.user.idOwner, resolution: 3})
			if (response) {
				showAlertMessage( this.$t('Access deleted'), 'InfoIcon', `🎉🎉 ${this.$t('Register deleted')}`, 'success', 4000, 'bottom-right')
				this.$emit('request-access-control')
				await this.pushData('onClick', 'goToDeleteMaintenanceDataRequest', 'goToDeleteMaintenanceDataRequest', this.$route.name)
			}
      		this.isUpdatingMyPropertyData = false

		}
	},
}
</script>

<style>
.custom-button-margin{
  margin: 0.3rem !important;
}
.p-custom{
  padding: 0.3rem;
}
.minHTable{
  min-height: 150px;
}
</style>