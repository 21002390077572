<template>
  <b-row class="">
    <b-col md="2" class="custom-margin">
      <!-- <label>{{$t('Entries')}}</label> -->
      <v-select
        v-model="porPagina"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="perPageOptions"
        :clearable="false"
        :placeholder="$t('Entries')"
        class="per-page-selector"
        @input="changePerPage"
      />
    </b-col>
    <b-col md="8" class="custom-margin">
      <b-form-input
        v-model="search"
        class="mr-1"
        :placeholder="$t('search in table')"
        type="search"
        @input="searchInRecords"
      />
    </b-col>
  </b-row>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  props:{
    perPage:{
      type: Number,
      required: true,
    },
    perPageOptions:{
      type: Array,
      required: true,
    },
    searchQuery:{
      type: String,
      required: true,
    },

  },
  components: {
    vSelect,
  },
  data() {
    return {
      porPagina: this.perPage,
      statusFilter: null,
      statusOptions : ['Downloaded','Draft','Paid','Partial Payment','Past Due',],
      search: this.searchQuery
    }
  },
  methods:{
    changePerPage( perPage ){
      this.$emit('change-per-page', perPage)
      this.porPagina = perPage
    },
    searchInRecords( query ){
      this.$emit('search-in-table-records', query)
      this.search = query
    }
  }
}
</script>
<style>
.custom-margin{
  margin: 0.3rem 0;
}
</style>