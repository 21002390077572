<template>
  <div style="padding: 1rem;">

    <b-card no-body>
      <b-card-body>
        <b-tabs v-model="tabIndex">
          <b-tab @click="setTab(0, $t('control access'))">
            <template #title>
              <feather-icon icon="FileTextIcon" size="18" />
              <span>{{ $t('control access')}} </span>
            </template>
          <AccessList
            @request-access-control="requestAccessControl"
            :isLoadingMyPropertyData="isLoadingMyPropertyData"
          />
          </b-tab>
          <b-tab @click="setTab(1, $t('maintenance requests'))" v-if="isOutOfPool">
            <template #title>
              <feather-icon icon="ToolIcon" size="18" />
              <span>{{ $t('maintenance requests')}} </span>
            </template>
          <RequestList
            @request-access-control="requestAccessControl"
            :isLoadingMyPropertyData="isLoadingMyPropertyData"
          />
          </b-tab>
          <!-- <b-tab @click="setTab(2, $t('parking access'))">
            <template #title>
              <feather-icon icon="FileTextIcon" size="18" />
              <span> {{$t('Montly Report')}} </span>
            </template>
          </b-tab> -->
        </b-tabs>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { utils } from '@/modules/owners/mixins/utils'
import AccessList from '@/modules/myProperty/components/AccessControl/AccessList'
import RequestList from '@/modules/myProperty/components/MaintenanceRequest/RequestList'
import { formatDate } from '@/helpers/helpers'

export default {
	mixins:[utils],
	components: {
		AccessList,
		RequestList
	},
	async created(){
		await this.getInitialContentMyProperty()
		if (!!this.$route?.params?.index && !!this.$route?.params?.info) await this.setTab(this.$route.params.index, this.$route.params.info)
	},
  data() {
    return {
      tabIndex: 0,
      isLoadingMyPropertyData: false
    }
  },
  watch: {
    myContracts: {
      deep: true,
      async handler() {
        await this.getInitialContentMyProperty()
      },
    },
  },
  computed: {
    ...mapState('auth', ['user','myContracts']),
    isOutOfPool(){
      return this.myContracts?.contractSelected?.membershipcode === 'CLASSIC'
    }
  },
  methods: {
    ...mapActions('myProperty', ['fetchControlAccess', 'fetchOwnerBookingsLite', 'fetchMaintenanceRequests']),
    ...mapMutations('myProperty', ['setScheduleControlAccess', 'setMaintenanceRequests', 'setBookingsDataLite']),
    async getInitialContentMyProperty(){
      const idContract = this.myContracts?.contractSelected?.id
      if (idContract){
        this.isLoadingMyPropertyData = true
        this.tabIndex = this.selectedTabProfits
        await this.requestAccessControl(idContract)
        await this.requestMaintenanceRequests(idContract)
        this.isLoadingMyPropertyData = false
      }
    },
    async setTab(index, infoTab){
      this.tabIndex = index
      await this.pushData('onClick', 'clickTabOption', 'clickTabOption', this.$route.name, infoTab) // push a back del registr
    },
    formDate(date, toFormat){
      return formatDate({date, language: this.user?.languageName, toFormat})
    },
    async requestAccessControl(idContract){
      const accessRegister = await this.fetchControlAccess({idContract})
      accessRegister.sort( (a,b) => { return new Date(b.createDate) - new Date(a.createDate) })
      const { housingid, id } = this.myContracts.contractSelected // hFousingid del contrado seleccionado
      const bookings = await this.fetchOwnerBookingsLite({housingid, idContract: id})
      // this.setMaintenanceRequests([])
	  await this.requestMaintenanceRequests(id)
	  
      this.setBookingsDataLite(bookings)
      this.setScheduleControlAccess(accessRegister)
    },
    async requestMaintenanceRequests(idContract){
      const requestRegister = await this.fetchMaintenanceRequests({idContract})
      requestRegister.sort( (a,b) => { return new Date(b.createDate) - new Date(a.createDate) })
      this.setMaintenanceRequests(requestRegister)
    }
  }
}
</script>
<style scoped>
.capitalize{
  text-transform: capitalize !important;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>